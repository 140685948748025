import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#3596B5",
    },
    secondary: {
      main: "#f3f0ec",
    },
  },
  typography: {
    fontSize: 16,
    fontFamily: "Graphik-Regular",
    h3: {
      fontWeight: 700,
      fontSize: "2rem",
    },
    h4: {
      fontWeight: 700,
      fontSize: "1.6rem",
    },
    h5: {
      fontWeight: 600,
      fontSize: "1.2rem",
    },
    h6: {
      marginLeft: "1.2em",
      fontWeight: 600,
    },
    button: {
      textTransform: "none",
    },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
});

export default theme;
