import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import React from "react";

interface Props {
  title: string;
  navigation?: boolean;
}

export default function Subheader(props: Props) {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        sx={{ padding: 0 }}
        position="static"
        className="subheader"
        color="secondary"
      >
        <Toolbar>
          {props.navigation && (
            <Button onClick={() => window.history.back()}>
              <ArrowBackIcon />
            </Button>
          )}
          <Typography
            variant="h5"
            component="div"
            className="subtitle"
            sx={{ flexGrow: 1 }}
          >
            {props.title}
          </Typography>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
