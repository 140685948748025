import LogoutIcon from "@mui/icons-material/Logout";
import { Button } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useContext } from "react";
import { useNavigate } from "react-location";
import { ReactComponent as Logo } from "../assets/logo-nestle.svg";
import { AuthContext } from "../context/auth.context";
import "./Header.css";

export default function Header() {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);

  function logout() {
    authContext?.setAuth({
      token: "",
      expires: 0,
      isAuth: false,
    });
    localStorage.removeItem("accessToken");
    sessionStorage.clear();
    navigate({ to: "/" });
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" className="header" color="primary">
        <Toolbar>
          <Logo />
          <Typography
            variant="h6"
            component="div"
            className="title"
            sx={{ flexGrow: 1 }}
          >
            MORE Web App
          </Typography>
          {authContext?.auth.isAuth &&
            authContext?.auth.expires &&
            authContext?.auth.expires > Date.now() && (
              <Button variant="outlined" color="secondary" onClick={logout}>
                {" "}
                Logout
                <LogoutIcon />
              </Button>
            )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
