import { Button } from "@mui/material";
import React, { useContext } from "react";
import { Navigate, useNavigate } from "react-location";
import { AuthContext } from "../context/auth.context";
import { userCredential } from "../utils/loginUtils";

export function Login() {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);

  async function login() {
    const accessToken = await userCredential.getToken(["user.read"]);

    authContext?.setAuth({
      token: accessToken.token,
      expires: accessToken.expiresOnTimestamp,
      isAuth: true,
    });

    navigate({ to: "/containers" });
  }

  if (
    authContext?.auth.isAuth &&
    authContext?.auth.expires &&
    authContext?.auth.expires > Date.now()
  ) {
    return <Navigate to="/containers" />;
  }

  return (
    <div className="login">
      <Button variant="contained" onClick={login}>
        Login
      </Button>
    </div>
  );
}
