import { ContainerItem } from "@azure/storage-blob";
import { decryptData } from "../utils/crypto";

declare global {
  interface Window {
    _env_: any;
  }
}

const backendUri = window._env_.BACKEND_URI;
const encryptionSalt = window._env_.ENCRYPTION_SALT;

export async function loadContainers() {
  let containers: ContainerItem[] = [];

  const encyptedAcessToken = localStorage.getItem("accessToken");

  if (encyptedAcessToken) {
    const accessToken = decryptData(encyptedAcessToken, encryptionSalt);

    if (accessToken !== null) {
      const token = JSON.parse(accessToken).token;
      try {
        const response = await fetch(backendUri + "/containers", {
          method: "GET",
          headers: {
            Authorization: "Bearer " + token,
          },
        });

        containers = await response.json();
      } catch (error: any) {
        alert("Error loading containers: " + error.message);
      }
    }
  }

  return containers;
}
