import { InteractiveBrowserCredential } from "@azure/identity";

declare global {
  interface Window {
    _env_: any;
  }
}

const clientId = window._env_.AZURE_CLIENT_ID;
const tenantId = window._env_.AZURE_TENANT_ID;

export const userCredential = new InteractiveBrowserCredential({
  clientId,
  tenantId,
});
