import { BlobItem, ContainerItem } from "@azure/storage-blob";
import { ThemeProvider } from "@mui/material/styles";
import React from "react";
import {
  MakeGenerics,
  Outlet,
  ReactLocation,
  Route,
  RouteMatch,
  Router,
} from "react-location";
import "./App.css";
import Header from "./components/Header";
import { Blobs } from "./pages/Blobs";
import { Containers } from "./pages/Containers";
import { Login } from "./pages/Login";
import theme from "./theme/theme";
import { loadBlobs } from "./utils/blobsUtils";
import { loadContainers } from "./utils/containersUtils";

export type LocationGenerics = MakeGenerics<{
  LoaderData: {
    containers: ContainerItem[] | { error: string };
    blobs: BlobItem[] | { error: string };
  };
}>;

const location = new ReactLocation();

const routes: Route[] = [
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "containers",
    children: [
      {
        path: "/",
        element: <Containers />,
        loader: async () => {
          return { containers: await loadContainers() };
        },
      },
      {
        path: ":containerName",
        element: <Blobs />,
        loader: async (routeMatch: RouteMatch) => {
          return { blobs: await loadBlobs(routeMatch.params.containerName) };
        },
      },
    ],
  },
];

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <Router location={location} routes={routes}>
          <Header />
          <Outlet />
          {/*<footer>Footer</footer>*/}
        </Router>
      </ThemeProvider>
    </>
  );
}

export default App;
