import SnippetFolderIcon from "@mui/icons-material/SnippetFolder";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import React, { useContext } from "react";
import { Link, Navigate, useMatch } from "react-location";
import { LocationGenerics } from "../App";
import Subheader from "../components/Subheader";
import { AuthContext } from "../context/auth.context";

export function Containers() {
  const routeMatch = useMatch<LocationGenerics>();
  const containers = routeMatch.data.containers;
  const authContext = useContext(AuthContext);

  if (Array.isArray(containers)) {
    if (
      authContext?.auth.isAuth &&
      authContext?.auth.expires &&
      authContext?.auth.expires > Date.now()
    ) {
      return (
        <>
          <Subheader title="List of containers"></Subheader>
          <Box sx={{ width: "100%", maxWidth: "100%" }}>
            <List component="nav" aria-label="main mailbox folders">
              {containers?.map((container, i) => {
                return (
                  <Link className="link" to={"/containers/" + container.name}>
                    <ListItemButton key={i}>
                      <ListItemIcon>
                        <SnippetFolderIcon />
                      </ListItemIcon>
                      <ListItemText primary={container.name} />
                    </ListItemButton>
                  </Link>
                );
              })}
            </List>
          </Box>
        </>
      );
    } else {
      localStorage.removeItem("accessToken");
      sessionStorage.clear();
      return <Navigate to="/" />;
    }
  } else {
    return (
      <>
        <Subheader title="List of containers"></Subheader>
        <Box sx={{ width: "100%", maxWidth: "100%" }}>
          <h3>{containers?.error} - Please contact the administrator</h3>
        </Box>
      </>
    );
  }
}
