import React, { createContext, useEffect, useState } from "react";
import { decryptData, encryptData } from "../utils/crypto";

declare global {
  interface Window {
    _env_: any;
  }
}

const encryptionSalt = window._env_.ENCRYPTION_SALT;

interface IAuth {
  token: string;
  expires: number;
  isAuth: boolean;
}

interface IAuthContext {
  auth: IAuth;
  setAuth: (state: IAuth) => void;
}

export const AuthContext = createContext<IAuthContext | null>(null);

export const AuthProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [auth, setAuth] = useState({
    token: "",
    expires: 0,
    isAuth: false,
  });

  useEffect(() => {
    if (auth.isAuth) {
      const encyptedAcessToken = encryptData(
        JSON.stringify(auth),
        encryptionSalt
      );
      localStorage.setItem("accessToken", encyptedAcessToken);
    }
  }, [auth]);

  useEffect(() => {
    const encyptedAcessToken = localStorage.getItem("accessToken");

    if (encyptedAcessToken) {
      const accessToken = decryptData(encyptedAcessToken, encryptionSalt);

      if (accessToken !== null) {
        setAuth({
          token: JSON.parse(accessToken).token,
          expires: JSON.parse(accessToken).expires,
          isAuth: true,
        });
      }
    }
  }, []);

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};
